import baseURL from "../../API/baseURL";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch products
export const fetchProducts = () => {
  return async dispatch => {
    try {
      const products = await baseURL.get("/api/v1/products");
      if (products) {
        dispatch(fetchProductsSuccess(products?.data?.data))
      }else{
        dispatch(fetchProductsSuccess([]));
      }
    } catch (error) {
      // يمكنك إضافة معالجة للأخطاء هنا
      console.error("Error fetching products:", error?.response?.data?.message || error?.response?.data||error?.message);
    }
  };
};
